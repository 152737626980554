import React, { useState, useEffect, useCallback } from "react";
import { supabase } from "../api/supabaseClient";
import { formatTimeAgo } from "../utils/timeAgo";
import { useTranslation } from "react-i18next";

const generatePaginationRange = (currentPage, totalPages) => {
  const delta = 2;
  const range = [];
  const rangeWithDots = [];

  // Always show first page
  range.push(1);

  for (let i = currentPage - delta; i <= currentPage + delta; i++) {
    if (i > 1 && i < totalPages) {
      range.push(i);
    }
  }

  // Always show last page
  if (totalPages > 1) {
    range.push(totalPages);
  }

  // Add dots where needed
  let l;
  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        rangeWithDots.push("...");
      }
    }
    rangeWithDots.push(i);
    l = i;
  }

  return rangeWithDots;
};

const Results = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const resultsPerPage = 10;
  const { t } = useTranslation();

  const fetchResults = useCallback(async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();

    if (user) {
      const from = (currentPage - 1) * resultsPerPage;
      const to = from + resultsPerPage - 1;

      const { count } = await supabase
        .from("images")
        .select("*", { count: "exact" })
        .eq("agent_id", user.id);

      setTotalCount(count);

      const { data, error } = await supabase
        .from("images")
        .select("*")
        .eq("agent_id", user.id)
        .order("created_at", { ascending: false })
        .range(from, to);

      if (error) {
        console.error("Error fetching results:", error);
      } else {
        setResults(data);
      }
      setLoading(false);
    }
  }, [currentPage]);

  useEffect(() => {
    fetchResults();
  }, [fetchResults]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-b from-gray-50 to-gray-100">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900">
            {" "}
            {t("app.pages.MyPhotos.title")}
          </h1>
        </div>

        {/* Results Table */}
        <div className="bg-white/80 backdrop-blur-sm shadow-lg rounded-xl border border-gray-200/50 overflow-hidden">
          <div className="px-6 py-5 border-b border-gray-200/50 bg-gradient-to-r from-gray-50 to-white">
            <h2 className="text-xl font-semibold bg-gradient-to-r from-gray-900 to-gray-700 bg-clip-text text-transparent">
              {t("app.pages.MyPhotos.allStagings")}
            </h2>
          </div>

          {/* Desktop Table View */}
          <div className="hidden md:block overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200/50">
              <thead className="bg-gradient-to-r from-gray-50 to-white">
                <tr>
                  <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    {t("app.pages.MyPhotos.table.headers.preview")}
                  </th>
                  <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    {t("app.pages.MyPhotos.table.headers.roomType")}
                  </th>
                  <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    {t("app.pages.MyPhotos.table.headers.styleType")}
                  </th>
                  <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    {t("app.pages.MyPhotos.table.headers.created")}
                  </th>
                  <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    {t("app.pages.MyPhotos.table.headers.status")}
                  </th>
                  <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    {t("app.pages.MyPhotos.table.headers.preview")}
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200/50">
                {results.map((result) => (
                  <tr
                    key={result.id}
                    className="hover:bg-gray-50/50 transition-colors"
                  >
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="h-16 w-16 rounded-lg overflow-hidden">
                        <img
                          src={result.input_image_url}
                          alt="Room preview"
                          className="h-full w-full object-cover"
                        />
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {result.room_type}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {result.style_type}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {formatTimeAgo(result.created_at)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span
                        className={`px-3 py-1 rounded-full text-xs font-medium
                        ${
                          result.status === "completed"
                            ? "bg-green-100 text-green-800"
                            : result.status === "processing"
                            ? "bg-yellow-100 text-yellow-800"
                            : "bg-red-100 text-red-800"
                        }`}
                      >
                        {result.status}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      <a
                        href={`/result/${result.id}`}
                        className="inline-flex items-center font-medium text-gray-700 hover:text-cyan-600 transition-all duration-300 group"
                      >
                        {t("app.pages.MyPhotos.table.viewResult")}
                        <svg
                          className="ml-1 w-4 h-4 transform translate-x-0 group-hover:translate-x-1 transition-transform duration-300"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 5l7 7-7 7"
                          />
                        </svg>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Mobile Card View */}
          <div className="md:hidden">
            {results.map((result) => (
              <div
                key={result.id}
                className="p-4 border-b border-gray-200/50 hover:bg-gray-50/50 transition-colors"
              >
                <div className="flex space-x-4">
                  <div className="h-20 w-20 flex-shrink-0">
                    <img
                      src={result.input_image_url}
                      alt={t("app.pages.MyPhotos.table.altText")}
                      className="h-full w-full object-cover rounded-lg"
                    />
                  </div>
                  <div className="flex-1">
                    <div className="flex justify-between">
                      <h3 className="text-sm font-semibold text-gray-900">
                        {result.room_type}
                      </h3>
                      <span className="text-xs text-gray-500">
                        {formatTimeAgo(result.created_at)}
                      </span>
                    </div>
                    <p className="text-sm text-gray-600 mt-1">
                      {result.style_type}
                    </p>
                    <div className="mt-2 flex justify-between items-center">
                      <span
                        className={`px-3 py-1 rounded-full text-xs font-medium
                        ${
                          result.status === "completed"
                            ? "bg-green-100 text-green-800"
                            : result.status === "processing"
                            ? "bg-yellow-100 text-yellow-800"
                            : "bg-red-100 text-red-800"
                        }`}
                      >
                        {result.status}
                      </span>
                      <a
                        href={`/result/${result.id}`}
                        className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-cyan-600 transition-all duration-300 group"
                      >
                        {t("app.pages.MyPhotos.table.viewResult")}
                        <svg
                          className="ml-1 w-4 h-4 transform translate-x-0 group-hover:translate-x-1 transition-transform duration-300"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 5l7 7-7 7"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Pagination */}
        <div className="mt-8 px-4">
          <div className="flex items-center justify-between bg-white/80 backdrop-blur-sm rounded-xl shadow-sm border border-gray-200/50 px-4 py-3 sm:px-6">
            <div className="flex flex-1 justify-between sm:hidden">
              <button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                className={`relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-md
          ${
            currentPage === 1
              ? "text-gray-400 bg-gray-50"
              : "text-gray-700 bg-white hover:bg-gray-50"
          } border border-gray-300 transition-colors duration-300`}
              >
                {t("app.pages.MyPhotos.pagination.previous")}
              </button>
              <button
                onClick={() =>
                  setCurrentPage((prev) =>
                    Math.min(prev + 1, Math.ceil(totalCount / resultsPerPage))
                  )
                }
                disabled={
                  currentPage === Math.ceil(totalCount / resultsPerPage)
                }
                className={`relative ml-3 inline-flex items-center px-4 py-2 text-sm font-medium rounded-md
          ${
            currentPage === Math.ceil(totalCount / resultsPerPage)
              ? "text-gray-400 bg-gray-50"
              : "text-gray-700 bg-white hover:bg-gray-50"
          } border border-gray-300 transition-colors duration-300`}
              >
                <span className="sr-only">
                  {t("app.pages.MyPhotos.pagination.srOnly.Next")}
                </span>
              </button>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  {t("app.pages.MyPhotos.pagination.showing", {
                    currentPage: currentPage,
                    totalPages: Math.ceil(totalCount / resultsPerPage),
                  })}
                </p>
              </div>
              <div>
                <nav
                  className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                  aria-label="Pagination"
                >
                  {/* Previous button */}
                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.max(prev - 1, 1))
                    }
                    disabled={currentPage === 1}
                    className={`relative inline-flex items-center px-3 py-2 rounded-l-md border text-sm font-medium
              ${
                currentPage === 1
                  ? "text-gray-400 bg-gray-50 cursor-not-allowed"
                  : "text-gray-500 bg-white hover:bg-gray-50"
              } border-gray-300 transition-colors duration-300`}
                  >
                    <span className="sr-only">
                      {t("app.pages.MyPhotos.pagination.srOnly.previous")}
                    </span>

                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>

                  {/* Page numbers */}
                  {generatePaginationRange(
                    currentPage,
                    Math.ceil(totalCount / resultsPerPage)
                  ).map((pageNum, idx) => (
                    <button
                      key={idx}
                      onClick={() =>
                        pageNum !== "..." && setCurrentPage(pageNum)
                      }
                      disabled={pageNum === "..."}
                      className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium
                ${
                  pageNum === currentPage
                    ? "z-10 bg-gradient-to-r from-teal-500 to-cyan-500 text-white border-cyan-500"
                    : pageNum === "..."
                    ? "text-gray-700 bg-white border-gray-300 cursor-default"
                    : "text-gray-700 bg-white border-gray-300 hover:bg-gray-50"
                } transition-colors duration-300`}
                    >
                      {pageNum}
                    </button>
                  ))}

                  {/* Next button */}
                  <button
                    onClick={() =>
                      setCurrentPage((prev) =>
                        Math.min(
                          prev + 1,
                          Math.ceil(totalCount / resultsPerPage)
                        )
                      )
                    }
                    disabled={
                      currentPage === Math.ceil(totalCount / resultsPerPage)
                    }
                    className={`relative inline-flex items-center px-3 py-2 rounded-r-md border text-sm font-medium
              ${
                currentPage === Math.ceil(totalCount / resultsPerPage)
                  ? "text-gray-400 bg-gray-50 cursor-not-allowed"
                  : "text-gray-500 bg-white hover:bg-gray-50"
              } border-gray-300 transition-colors duration-300`}
                  >
                    <span className="sr-only">
                      {t("app.pages.MyPhotos.pagination.srOnly.next")}
                    </span>
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Results;
