import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useProgressBar = (status, timeElapsed) => {
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    // If completed, show 100%
    if (status === 'completed') {
      setProgress(100);
      setMessage(t('app.hooks.progressBar.messages.complete'));
      return;
    }

    // Convert timeElapsed from milliseconds to seconds
    const seconds = timeElapsed / 1000;

    // Calculate progress based on time segments
    let currentProgress;
    if (seconds <= 5) {
      // 0-5s: Linear progress to 50%
      currentProgress = (seconds / 5) * 50;
      setMessage(t('app.hooks.progressBar.messages.initializing'));
    } else if (seconds <= 20) {
      // 5-20s: Progress from 50% to 75%
      currentProgress = 50 + ((seconds - 5) / 15) * 25;
      setMessage(t('app.hooks.progressBar.messages.processing'));
    } else if (seconds <= 45) {
      // 20-45s: Progress from 75% to 95%
      currentProgress = 75 + ((seconds - 20) / 25) * 20;
      setMessage(t('app.hooks.progressBar.messages.finalizing'));
    } else {
      // After 45s: Stay at 95%
      currentProgress = 95;
      setMessage(t('app.hooks.progressBar.messages.almostDone'));
    }

    setProgress(Math.min(currentProgress, 95)); // Ensure we never exceed 95% unless complete
  }, [timeElapsed, status, t]);

  return { progress, message };
};

export default useProgressBar;