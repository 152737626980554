import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const InsufficientCreditsModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full">
        <h2 className="text-2xl font-bold mb-4">
          {t("app.components.free-order-images.insufficient_credits_modal.title")}
        </h2>
        <p className="mb-6">
          {t("app.components.free-order-images.insufficient_credits_modal.description")}
        </p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition duration-200"
          >
            {t("app.components.free-order-images.insufficient_credits_modal.close_button")}
          </button>
          <button
            onClick={() => navigate("/prices")}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-200"
          >
            {t("app.components.free-order-images.insufficient_credits_modal.view_pricing_button")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InsufficientCreditsModal;