import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../api/supabaseClient";
import { FaUpload, FaArrowLeft, FaTrash } from "react-icons/fa";
import Select from "react-select";
import InsufficientCreditsModal from "../components/InsufficientCreditsModal";
import { Tooltip } from "react-tooltip";
import StagingTypeSelector from "../components/StagingTypeSelector";
import { BottomSheet } from "../components/BottomSheet";
import { useTranslation } from "react-i18next";
import { API_URL } from "../config";

const ALLOWED_FILE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/gif",
  "image/webp",
];
const MAX_FILE_SIZE = 15 * 1024 * 1024; // 15 MB in bytes

const UploadNewImage = () => {
  const { t } = useTranslation();
  const [image, setImage] = useState(null);
  const [roomType, setRoomType] = useState("");
  const [styleType, setStyleType] = useState("");
  const [stagingType, setStagingType] = useState("");
  const [preview, setPreview] = useState("");
  const [credits, setCredits] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [showInsufficientCreditsModal, setShowInsufficientCreditsModal] =
    useState(false);

  const [roomTypeSheetOpen, setRoomTypeSheetOpen] = useState(false);
  const [styleTypeSheetOpen, setStyleTypeSheetOpen] = useState(false);

  const ROOM_TYPES = [
    {
      value: "Bedroom",
      label: t("app.pages.UploadNewImage.roomTypes.bedroom"),
      display_value: t("app.pages.UploadNewImage.roomTypes.bedroom"),
    },
    {
      value: "Living room",
      label: t("app.pages.UploadNewImage.roomTypes.livingRoom"),
      display_value: t("app.pages.UploadNewImage.roomTypes.livingRoom"),
    },
    {
      value: "Children's bedroom",
      label: t("app.pages.UploadNewImage.roomTypes.childrensBedroom"),
      display_value: t("app.pages.UploadNewImage.roomTypes.childrensBedroom"),
    },
    {
      value: "Dining room",
      label: t("app.pages.UploadNewImage.roomTypes.diningRoom"),
      display_value: t("app.pages.UploadNewImage.roomTypes.diningRoom"),
    },
    {
      value: "Home Office",
      label: t("app.pages.UploadNewImage.roomTypes.homeOffice"),
      display_value: t("app.pages.UploadNewImage.roomTypes.homeOffice"),
    },
  ];

  const STYLE_TYPES = [
    {
      value: "Standard",
      label: t("app.pages.UploadNewImage.styleTypes.standard"),
      display_value: t("app.pages.UploadNewImage.styleTypes.standard"),
    },
    {
      value: "Modern",
      label: t("app.pages.UploadNewImage.styleTypes.modern"),
      display_value: t("app.pages.UploadNewImage.styleTypes.modern"),
    },
    {
      value: "Scandinavian",
      label: t("app.pages.UploadNewImage.styleTypes.scandinavian"),
      display_value: t("app.pages.UploadNewImage.styleTypes.scandinavian"),
    },
    {
      value: "Industrial",
      label: t("app.pages.UploadNewImage.styleTypes.industrial"),
      display_value: t("app.pages.UploadNewImage.styleTypes.industrial"),
    },
    {
      value: "Luxury",
      label: t("app.pages.UploadNewImage.styleTypes.luxury"),
      display_value: t("app.pages.UploadNewImage.styleTypes.luxury"),
    },
    {
      value: "Coastal",
      label: t("app.pages.UploadNewImage.styleTypes.coastal"),
      display_value: t("app.pages.UploadNewImage.styleTypes.coastal"),
    },
  ];

  const STYLE_DESCRIPTIONS = {
    Standard: t("app.pages.UploadNewImage.styleDescriptions.standard"),
    Modern: t("app.pages.UploadNewImage.styleDescriptions.modern"),
    Scandinavian: t("app.pages.UploadNewImage.styleDescriptions.scandinavian"),
    Industrial: t("app.pages.UploadNewImage.styleDescriptions.industrial"),
    Luxury: t("app.pages.UploadNewImage.styleDescriptions.luxury"),
    Coastal: t("app.pages.UploadNewImage.styleDescriptions.coastal"),
    Farmhouse: t("app.pages.UploadNewImage.styleDescriptions.farmhouse"),
  };

  useEffect(() => {
    fetchUserCredits();
  }, []);

  const fetchUserCredits = async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    if (user) {
      const { data, error } = await supabase
        .from("agents")
        .select("credits")
        .eq("id", user.id)
        .single();

      if (error) {
        console.error("Error fetching user credits:", error);
      } else {
        setCredits(data.credits);
      }
    }
  };

  const sanitizeFileName = (fileName) => {
    // Remove any characters that aren't alphanumeric, underscores, hyphens, or periods
    return fileName.replace(/[^a-zA-Z0-9_.-]/g, "");
  };

  const validateFile = (file) => {
    if (!file) {
      return t("app.pages.UploadNewImage.errors.selectImage");
    }

    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      return t("app.pages.UploadNewImage.errors.invalidFileType");
    }

    if (file.size > MAX_FILE_SIZE) {
      return t("app.pages.UploadNewImage.errors.fileSizeLimit");
    }

    return null;
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const validationError = validateFile(file);

    if (validationError) {
      setError(validationError);
      setImage(null);
      setPreview("");
    } else {
      const sanitizedFileName = sanitizeFileName(file.name);
      const renamedFile = new File([file], sanitizedFileName, {
        type: file.type,
      });
      setImage(renamedFile);
      setPreview(URL.createObjectURL(file));
      setError("");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!image || !roomType || !styleType || !stagingType) {
      setError(t("app.pages.UploadNewImage.errors.fillAllFields"));
      return;
    }
    if (credits < 1) {
      setShowInsufficientCreditsModal(true);
      return;
    }

    setLoading(true);
    setError("");

    try {
      const {
        data: { user },
      } = await supabase.auth.getUser();

      // Find the selected room and style types to get their display values
      const selectedRoomType = ROOM_TYPES.find((rt) => rt.value === roomType);
      const selectedStyleType = STYLE_TYPES.find(
        (st) => st.value === styleType
      );

      const formData = new FormData();
      formData.append("file", image);
      formData.append("room_type", selectedRoomType.value); // English value for AI
      formData.append("room_type_display", selectedRoomType.display_value); // Translated value for DB
      formData.append("style_type", selectedStyleType.value); // English value for AI
      formData.append("style_type_display", selectedStyleType.display_value); // Translated value for DB
      formData.append("user_id", user.id);
      formData.append("staging_type", stagingType);

      const response = await fetch(`${API_URL}/api/v1/process-image`.trim(), {
        method: "POST",
        body: formData,
        credentials: "include",
        headers: {
          Accept: "application/json",
        },
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(
          result.error || result.details || "Failed to process image"
        );
      }

      const imageId = result.image_id;

      if (!imageId) {
        throw new Error(t("app.pages.UploadNewImage.errors.noImageId"));
      }

      // Navigate to the processing page with the image ID
      navigate(`/processing/${imageId}`);
    } catch (error) {
      setError(
        error.message || t("app.pages.UploadNewImage.errors.processingError")
      );
    } finally {
      setLoading(false);
    }
  };

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#0198FE" : "#e2e8f0",
      boxShadow: state.isFocused ? "0 0 0 1px #0198FE" : provided.boxShadow,
      "&:hover": {
        borderColor: "#0198FE",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#0198FE"
        : state.isFocused
        ? "#0198FE20"
        : provided.backgroundColor,
      "&:active": {
        backgroundColor: "#0182d8",
      },
    }),
  };

  const handleRemoveImage = () => {
    setImage(null);
    setPreview("");
    setError("");
    // Reset the file input
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = "";
    }
  };

  return (
    <div className="px-4 py-6 sm:px-0">
      <div className="mb-6">
        <button
          onClick={() => navigate("/dashboard")}
          className="flex items-center text-[#0198FE] hover:text-[#0182d8] transition-colors duration-300"
        >
          <FaArrowLeft className="mr-2" />{" "}
          {t("app.pages.UploadNewImage.backToDashboard")}
        </button>
      </div>

      <div className="bg-white shadow-lg rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h1 className="text-xl font-semibold text-gray-900 mb-6">
            {t("app.pages.UploadNewImage.createNewStaging")}
          </h1>

          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Staging Type Selector - Always visible */}
            <div className="mb-6">
              <StagingTypeSelector
                value={stagingType}
                onChange={(value) => setStagingType(value)}
              />
            </div>

            {/* Rest of the form - Only visible after staging type is selected */}
            {stagingType && (
              <>
                {/* Mobile Layout */}
                <div className="md:hidden space-y-6">
                  {/* Image Upload */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      {t("app.pages.UploadNewImage.uploadPhoto")}
                    </label>
                    <label className="flex flex-col w-full h-40 border-2 border-dashed border-gray-300 rounded-lg hover:border-[#0198FE]/50 transition-colors cursor-pointer">
                      <div className="flex flex-col items-center justify-center h-full">
                        {preview ? (
                          <div className="relative w-full h-full">
                            <img
                              src={preview}
                              alt="Preview"
                              className="h-full w-full object-cover rounded-lg"
                            />
                            <button
                              type="button"
                              onClick={handleRemoveImage}
                              className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1.5 hover:bg-red-600 transition-colors"
                            >
                              <FaTrash className="h-3 w-3" />
                            </button>
                          </div>
                        ) : (
                          <div className="flex flex-col items-center">
                            <FaUpload className="h-8 w-8 text-gray-400 mb-2" />
                            <p className="text-sm text-gray-500">
                              {t("app.pages.UploadNewImage.uploadAnImage")}
                            </p>
                          </div>
                        )}
                      </div>
                      <input
                        type="file"
                        className="hidden"
                        onChange={handleImageUpload}
                        accept="image/*"
                      />
                    </label>
                  </div>

                  {/* Room Type */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      {t("app.pages.UploadNewImage.roomType")}
                    </label>
                    <button
                      type="button"
                      onClick={() => setRoomTypeSheetOpen(true)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg text-left text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#0198FE] focus:border-transparent"
                    >
                      {roomType
                        ? ROOM_TYPES.find((type) => type.value === roomType)
                            ?.label
                        : t("app.pages.UploadNewImage.selectRoomType")}
                    </button>
                  </div>

                  {/* Style Type */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      {t("app.pages.UploadNewImage.styleType")}
                    </label>
                    <button
                      type="button"
                      onClick={() => setStyleTypeSheetOpen(true)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg text-left text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#0198FE] focus:border-transparent"
                    >
                      {styleType
                        ? STYLE_TYPES.find((type) => type.value === styleType)
                            ?.label
                        : t("app.pages.UploadNewImage.selectStyleType")}
                    </button>
                  </div>

                  {/* Bottom Sheets */}
                  <BottomSheet
                    isOpen={roomTypeSheetOpen}
                    onClose={() => setRoomTypeSheetOpen(false)}
                    title={t("app.pages.UploadNewImage.selectRoomType")}
                  >
                    <div className="space-y-2">
                      {ROOM_TYPES.map((type) => (
                        <button
                          key={type.value}
                          onClick={() => {
                            setRoomType(type.value);
                            setRoomTypeSheetOpen(false);
                          }}
                          className={`w-full px-4 py-3 text-left rounded-lg transition-colors ${
                            roomType === type.value
                              ? "bg-[#0198FE] text-white"
                              : "hover:bg-gray-100"
                          }`}
                        >
                          {type.label}
                        </button>
                      ))}
                    </div>
                  </BottomSheet>

                  <BottomSheet
                    isOpen={styleTypeSheetOpen}
                    onClose={() => setStyleTypeSheetOpen(false)}
                    title={t("app.pages.UploadNewImage.selectStyleType")}
                  >
                    <div className="space-y-3">
                      {STYLE_TYPES.map((type) => (
                        <button
                          key={type.value}
                          onClick={() => {
                            setStyleType(type.value);
                            setStyleTypeSheetOpen(false);
                          }}
                          className={`w-full px-4 py-3 text-left rounded-lg transition-colors ${
                            styleType === type.value
                              ? "bg-[#0198FE]"
                              : "hover:bg-gray-100"
                          }`}
                        >
                          <div className="flex flex-col">
                            <span
                              className={`font-medium ${
                                styleType === type.value
                                  ? "text-white"
                                  : "text-gray-900"
                              }`}
                            >
                              {type.label}
                            </span>
                            <span
                              className={`text-sm mt-1 ${
                                styleType === type.value
                                  ? "text-white/80"
                                  : "text-gray-500"
                              }`}
                            >
                              {STYLE_DESCRIPTIONS[type.value]}
                            </span>
                          </div>
                        </button>
                      ))}
                    </div>
                  </BottomSheet>
                </div>

                {/* Desktop Layout */}
                <div className="hidden md:grid md:grid-cols-2 gap-6">
                  {/* Left Column */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      {t("app.pages.UploadNewImage.uploadPhoto")}
                    </label>
                    <label className="flex flex-col w-full h-[280px] border-2 border-dashed border-gray-300 rounded-lg hover:border-[#0198FE]/50 transition-colors cursor-pointer">
                      <div className="flex flex-col items-center justify-center h-full">
                        {preview ? (
                          <div className="relative w-full h-full">
                            <img
                              src={preview}
                              alt="Preview"
                              className="h-full w-full object-cover rounded-lg"
                            />
                            <button
                              type="button"
                              onClick={handleRemoveImage}
                              className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-2 hover:bg-red-600 transition-colors"
                            >
                              <FaTrash className="h-4 w-4" />
                            </button>
                          </div>
                        ) : (
                          <div className="flex flex-col items-center">
                            <FaUpload className="h-12 w-12 text-gray-400 mb-3" />
                            <p className="text-sm text-gray-500">
                              {t("app.pages.UploadNewImage.uploadAnImage")}
                            </p>
                          </div>
                        )}
                      </div>
                      <input
                        type="file"
                        className="hidden"
                        onChange={handleImageUpload}
                        accept="image/*"
                      />
                    </label>
                  </div>

                  {/* Right Column */}
                  <div className="space-y-6">
                    {/* Room Type */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        {t("app.pages.UploadNewImage.roomType")}
                      </label>
                      <Select
                        options={ROOM_TYPES}
                        value={ROOM_TYPES.find(
                          (type) => type.value === roomType
                        )}
                        onChange={(selectedOption) =>
                          setRoomType(selectedOption.value)
                        }
                        styles={customSelectStyles}
                        placeholder={t(
                          "app.pages.UploadNewImage.selectRoomType"
                        )}
                      />
                    </div>

                    {/* Style Type */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        {t("app.pages.UploadNewImage.styleType")}
                      </label>
                      <Select
                        options={STYLE_TYPES.map((type) => ({
                          ...type,
                          label: (
                            <div
                              data-tooltip-id={`style-tooltip-${type.value}`}
                            >
                              {type.label}
                            </div>
                          ),
                        }))}
                        value={STYLE_TYPES.find(
                          (type) => type.value === styleType
                        )}
                        onChange={(selectedOption) =>
                          setStyleType(selectedOption.value)
                        }
                        styles={customSelectStyles}
                        placeholder={t(
                          "app.pages.UploadNewImage.selectStyleType"
                        )}
                      />
                      {STYLE_TYPES.map((type) => (
                        <Tooltip
                          key={type.value}
                          id={`style-tooltip-${type.value}`}
                          content={STYLE_DESCRIPTIONS[type.value]}
                          place="right"
                        />
                      ))}
                    </div>

                    {/* Credits and Submit Button */}
                    <div className="pt-2">
                      <div className="flex items-center justify-between mb-3">
                        <span className="text-sm text-gray-500">
                          {t("app.pages.UploadNewImage.credits", {
                            count: credits,
                          })}
                        </span>
                      </div>
                      <button
                        type="submit"
                        disabled={loading}
                        className="w-full bg-[#0198FE] hover:bg-[#0182d8] text-white font-bold py-2.5 px-4 rounded-lg
                          transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        {loading
                          ? t("app.pages.UploadNewImage.processing")
                          : t("app.pages.UploadNewImage.startStaging")}
                      </button>
                    </div>
                  </div>
                </div>

                {/* Mobile Submit Button */}
                <div className="md:hidden">
                  <div className="flex items-center justify-between mb-3">
                    <span className="text-sm text-gray-500">
                      {t("app.pages.UploadNewImage.credits", {
                        count: credits,
                      })}
                    </span>
                  </div>
                  <button
                    type="submit"
                    disabled={loading}
                    className="w-full bg-[#0198FE] hover:bg-[#0182d8] text-white font-bold py-2.5 px-4 rounded-lg
                      transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {loading
                      ? t("app.pages.UploadNewImage.processing")
                      : t("app.pages.UploadNewImage.startStaging")}
                  </button>
                </div>
              </>
            )}

            {/* Error Message */}
            {error && (
              <div className="rounded-md bg-red-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg
                      className="h-5 w-5 text-red-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-red-700">{error}</p>
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>

      {/* Modals */}
      <InsufficientCreditsModal
        isOpen={showInsufficientCreditsModal}
        onClose={() => setShowInsufficientCreditsModal(false)}
      />
    </div>
  );
};

export default UploadNewImage;
