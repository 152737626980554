import i18next from "i18next";

export const formatTimeAgo = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  const diffInSeconds = Math.floor((now - date) / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInSeconds < 60) {
    return i18next.t("app.utils.timeAgo.justNow");
  } else if (diffInMinutes < 60) {
    return i18next.t("app.utils.timeAgo.minutesAgo", {
      count: diffInMinutes,
      plural: diffInMinutes === 1 ? "" : "s",
    });
  } else if (diffInHours < 24) {
    return i18next.t("app.utils.timeAgo.hoursAgo", {
      count: diffInHours,
      plural: diffInHours === 1 ? "" : "s",
    });
  } else if (diffInDays < 30) {
    if (diffInHours % 24 === 0) {
      return i18next.t("app.utils.timeAgo.daysAgo", {
        count: diffInDays,
        plural: diffInDays === 1 ? "" : "s",
      });
    }
    const remainingHours = diffInHours % 24;
    return i18next.t("app.utils.timeAgo.daysAndHoursAgo", {
      days: diffInDays,
      daysPlural: diffInDays === 1 ? "" : "s",
      hours: remainingHours,
      hoursPlural: remainingHours === 1 ? "" : "s",
    });
  } else {
    return date.toLocaleDateString();
  }
};
