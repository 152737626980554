// src/components/Layout.js
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../api/supabaseClient";
import Header from "./Header";

const Layout = ({ children, fullWidth = false }) => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const fetchUserData = useCallback(async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    if (user) {
      setUser(user);
    } else {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  return (
    <div className={`min-h-screen ${!fullWidth ? 'bg-gray-100' : ''}`}>
      <Header user={user} />
      <main className={fullWidth ? '' : 'max-w-7xl mx-auto py-6 sm:px-6 lg:px-8'}>
        {children}
      </main>
    </div>
  );
};

export default Layout;