import React, { useState, useEffect } from "react";
import { supabase } from "../api/supabaseClient";
import { FaCoins, FaImage } from "react-icons/fa";
import { formatTimeAgo } from "../utils/timeAgo";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const [credits, setCredits] = useState(0);
  const [recentProjects, setRecentProjects] = useState([]);
  const [totalStagings, setTotalStagings] = useState(0);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchDashboardData = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (user) {
        // Fetch credits
        const { data: agentData, error: agentError } = await supabase
          .from("agents")
          .select("credits")
          .eq("id", user.id)
          .single();

        if (agentError) {
        } else {
          setCredits(agentData.credits);
        }

        const { data: recentData, error: recentError } = await supabase
          .from("images")
          .select("id, input_image_url, room_type, style_type, created_at")
          .eq("agent_id", user.id)
          .order("created_at", { ascending: false })
          .limit(6);

        if (recentError) {
        } else {
          setRecentProjects(recentData);
        }

        // Fetch total stagings
        const { count, error: countError } = await supabase
          .from("images")
          .select("id", { count: "exact" })
          .eq("agent_id", user.id);

        if (countError) {
        } else {
          setTotalStagings(count);
        }
      }
      setLoading(false);
    };

    fetchDashboardData();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-b from-gray-50 to-gray-100">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900">
            {t("app.pages.dashboard.title")}
          </h1>
        </div>

        {credits === 0 && (
          <div className="mb-8 bg-[#0C0D0D] rounded-xl overflow-hidden shadow-lg border border-gray-800">
            <div className="p-6 sm:p-8">
              <div className="flex flex-col sm:flex-row items-center justify-between gap-6">
                <div className="flex-1 text-center sm:text-left">
                  <h2 className="text-2xl font-bold text-white mb-2">
                    {t("app.pages.dashboard.noCredits.title")}
                  </h2>
                  <p className="text-gray-400 mb-4">
                    {t("app.pages.dashboard.noCredits.description")}
                  </p>
                  <a
                    href="/choose-plan"
                    className="inline-flex items-center px-6 py-3 rounded-lg text-[#0C0D0D] font-medium bg-white hover:bg-gray-200 transform hover:-translate-y-0.5 transition-all duration-300 shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
                  >
                    {t("app.pages.dashboard.noCredits.choosePlanButton")}
                    <svg
                      className="ml-2 w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 7l5 5m0 0l-5 5m5-5H6"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mb-8">
          <div className="bg-white/80 backdrop-blur-sm overflow-hidden rounded-xl shadow-lg border border-gray-200/50 hover:shadow-xl transition-all duration-300">
            <div className="px-6 py-5">
              <div className="flex items-center">
                <div className="flex-shrink-0 bg-gradient-to-br from-[#0198FE] to-[#0C0D0D] rounded-lg p-3 shadow-lg">
                  <FaCoins className="h-6 w-6 text-white" />
                </div>
                <div className="ml-5 w-0 flex-1">
                  <dl>
                    <dt className="text-sm font-medium text-gray-600">
                      {t("app.pages.dashboard.stats.credits")}
                    </dt>
                    <dd className="text-2xl font-semibold text-[#0C0D0D]">
                      {credits}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white/80 backdrop-blur-sm overflow-hidden rounded-xl shadow-lg border border-gray-200/50 hover:shadow-xl transition-all duration-300">
            <div className="px-6 py-5">
              <div className="flex items-center">
                <div className="flex-shrink-0 bg-gradient-to-br from-[#0C0D0D] to-[#1A1A1A] rounded-lg p-3 shadow-lg">
                  <FaImage className="h-6 w-6 text-white" />
                </div>
                <div className="ml-5 w-0 flex-1">
                  <dl>
                    <dt className="text-sm font-medium text-gray-600">
                      {t("app.pages.dashboard.stats.totalStagings")}
                    </dt>
                    <dd className="text-2xl font-semibold text-[#0C0D0D]">
                      {totalStagings}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white/80 backdrop-blur-sm shadow-lg rounded-xl border border-gray-200/50 overflow-hidden">
          <div className="px-6 py-5 border-b border-gray-200/50 bg-gradient-to-r from-gray-50 to-white">
            <h2 className="text-xl font-semibold bg-gradient-to-r from-gray-900 to-gray-700 bg-clip-text text-transparent">
              {t("app.pages.dashboard.recentProjects.title")}
            </h2>
          </div>

          <div className="hidden md:block overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200/50">
              <thead className="bg-gradient-to-r from-gray-50 to-white">
                <tr>
                  <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    {t("app.pages.dashboard.recentProjects.columns.roomType")}
                  </th>
                  <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    {t("app.pages.dashboard.recentProjects.columns.styleType")}
                  </th>
                  <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    {t("app.pages.dashboard.recentProjects.columns.created")}
                  </th>
                  <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    {t("app.pages.dashboard.recentProjects.columns.action")}
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200/50">
                {recentProjects.map((project) => (
                  <tr
                    key={project.id}
                    className="hover:bg-gray-50/50 transition-colors"
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {project.room_type}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {project.style_type}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {formatTimeAgo(project.created_at)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      <a
                        href={`/result/${project.id}`}
                        className="inline-flex items-center font-medium text-gray-700 hover:text-cyan-600 transition-all duration-300 group"
                      >
                        {t("app.pages.dashboard.recentProjects.viewResult")}
                        <svg
                          className="ml-1 w-4 h-4 transform translate-x-0 group-hover:translate-x-1 transition-transform duration-300"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 5l7 7-7 7"
                          />
                        </svg>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="md:hidden">
            {recentProjects.map((project) => (
              <div
                key={project.id}
                className="p-4 border-b border-gray-200/50 hover:bg-gray-50/50 transition-colors"
              >
                <div className="space-y-2">
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="text-sm font-semibold text-gray-900">
                        {project.room_type}
                      </h3>
                      <p className="text-sm text-gray-600 mt-1">
                        {project.style_type}
                      </p>
                    </div>
                    <span className="text-xs text-gray-500">
                      {formatTimeAgo(project.created_at)}
                    </span>
                  </div>
                  <div className="pt-2">
                    <a
                      href={`/result/${project.id}`}
                      className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-cyan-600 transition-all duration-300 group"
                    >
                      {t("app.pages.dashboard.recentProjects.viewResult")}
                      <svg
                        className="ml-1 w-4 h-4 transform translate-x-0 group-hover:translate-x-1 transition-transform duration-300"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="mt-6 flex justify-center">
          <a
            href="/results"
            className="inline-flex items-center px-6 py-3 rounded-xl text-white font-medium bg-[#0198FE] hover:bg-[#0182d8] transform hover:-translate-y-0.5 transition-all duration-300 shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#0198FE]"
          >
            {t("app.pages.dashboard.viewAllResults")}
            <svg
              className="ml-2 w-5 h-5 transform translate-x-0 group-hover:translate-x-1 transition-transform duration-300"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 7l5 5m0 0l-5 5m5-5H6"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
