import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { supabase } from "../api/supabaseClient";
import useProgressBar from "../hooks/useProgressBar";
import { useTranslation } from "react-i18next";
import { API_URL } from "../config";

const Processing = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState("pending");
  const [error, setError] = useState(null);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [originalImage, setOriginalImage] = useState(null);

  // Use our custom progress hook
  const { progress, message } = useProgressBar(status, timeElapsed);

  // Fetch original image
  useEffect(() => {
    const fetchOriginalImage = async () => {
      const { data, error } = await supabase
        .from("images")
        .select("input_image_url")
        .eq("id", params.imageId)
        .single();

      if (!error && data) {
        setOriginalImage(data.input_image_url);
      }
    };

    fetchOriginalImage();
  }, [params.imageId]);

  // Main processing logic
  useEffect(() => {
    const imageId = params.imageId;

    const handleStatusUpdate = (replicateData, imageData) => {
      // First check database status
      if (imageData.status === "completed") {
        navigate(`/result/${imageId}`);
        return;
      }

      // Then check Replicate status
      if (replicateData.status === "succeeded") {
        setStatus("processing");
      } else if (replicateData.status === "failed") {
        setError("Image processing failed. Please try again.");
      } else {
        setStatus(replicateData.status);
      }
    };

    const checkStatus = async () => {
      if (!imageId) {
        setError("No image ID provided");
        return;
      }

      try {
        // First, check database status
        const { data: imageData, error: imageError } = await supabase
          .from("images")
          .select("status, prediction_id, upscale_prediction_id")
          .eq("id", imageId)
          .single();

        if (imageError) {
          setError(`Error checking status: ${imageError.message}`);
          return;
        }

        // If status is completed, navigate immediately
        if (imageData.status === "completed") {
          navigate(`/result/${imageId}`);
          return;
        }

        // Otherwise, check prediction status
        const predictionIdToCheck =
          imageData.upscale_prediction_id || imageData.prediction_id;

        const response = await fetch(
          `${API_URL}/api/v1/check-prediction/${predictionIdToCheck}`.trim(),
          {
            credentials: "include",
          }
        );

        if (!response.ok) {
          if (
            response.status === 404 &&
            imageData.prediction_id !== predictionIdToCheck
          ) {
            const finalResponse = await fetch(
              `${API_URL}/api/v1/check-prediction/${imageData.prediction_id}`.trim(),
              {
                credentials: "include",
              }
            );
            if (finalResponse.ok) {
              const finalData = await finalResponse.json();
              handleStatusUpdate(finalData, imageData);
              return;
            }
          }
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        handleStatusUpdate(data, imageData);
      } catch (err) {
        setError(`Unexpected error: ${err.message}`);
      }
    };

    checkStatus();
    const intervalId = setInterval(checkStatus, 5000);
    const timeInterval = setInterval(
      () => setTimeElapsed((prev) => prev + 1000),
      1000
    );

    return () => {
      clearInterval(intervalId);
      clearInterval(timeInterval);
    };
  }, [params, navigate]);

  const handleRetry = () => {
    navigate("/upload-image");
  };

  const getStatusMessage = () => {
    if (error) return error;
    if (status === "pending")
      return t("app.pages.processing.status.initializing");
    return message;
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#0C0D0D] to-[#1A1A1A]">
      <div className="container mx-auto px-4 py-8">
        {/* Desktop Layout */}
        <div className="hidden md:flex gap-8 h-[calc(100vh-12rem)]">
          {/* Original Image - Left Side */}
          <div className="w-1/2 bg-black/40 backdrop-blur-sm rounded-xl p-6 border border-white/10">
            <h3 className="text-lg font-medium mb-4 text-white/90">
              {t("app.pages.processing.originalImage")}
            </h3>
            <div className="h-[90%] rounded-lg overflow-hidden">
              {originalImage && (
                <img
                  src={originalImage}
                  alt={t("app.pages.processing.originalImageAlt")}
                  className="w-full h-full object-contain"
                />
              )}
            </div>
          </div>

          {/* Processing Preview - Right Side */}
          <div className="w-1/2 bg-black/40 backdrop-blur-sm rounded-xl p-6 border border-white/10">
            <h3 className="text-lg font-medium mb-4 text-white/90">
              {t("app.pages.processing.processingPreview")}
            </h3>
            <div className="relative h-[90%]">
              <div className="h-full rounded-lg overflow-hidden bg-[#0C0D0D]">
                {originalImage && (
                  <img
                    src={originalImage}
                    alt={t("app.pages.processing.previewImageAlt")}
                    className="w-full h-full object-contain blur-md brightness-50"
                  />
                )}
              </div>

              {/* Progress Overlay */}
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full px-8">
                {!error ? (
                  <div className="bg-black/50 rounded-xl p-8">
                    {/* Loading Spinner */}
                    <div className="flex items-center justify-center gap-2 mb-4">
                      <div className="animate-spin rounded-full h-5 w-5 border-2 border-white border-t-transparent" />
                      <span className="text-white/90">
                        {t("app.pages.processing.status.processing")}
                      </span>
                    </div>

                    <div className="text-center mb-4">
                      <span className="text-4xl font-bold text-white">
                        {t("app.pages.processing.progress.percent", {
                          percent: Math.round(progress),
                        })}
                      </span>
                    </div>

                    {/* Progress Bar */}
                    <div className="w-full bg-gray-700 rounded-full h-2 mb-4 overflow-hidden">
                      <div
                        className="bg-white h-2 rounded-full transition-all duration-300 ease-out"
                        style={{
                          width: `${progress}%`,
                          transition: "width 0.5s ease-out",
                        }}
                      >
                        <div className="animate-pulse bg-white/50 h-full w-full" />
                      </div>
                    </div>

                    <div className="text-center">
                      <p className="mb-2 text-white text-lg font-medium">
                        {getStatusMessage()}
                      </p>
                      <p className="text-gray-200">
                        {timeElapsed > 45000
                          ? t("app.pages.processing.status.takingLonger")
                          : t("app.pages.processing.status.lessThanMinute")}
                      </p>
                      <p className="text-gray-200">
                        {t("app.pages.processing.status.saveNotice")}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="bg-black/50 rounded-xl p-8">
                    <div className="text-center">
                      <div className="mb-4">
                        <svg
                          className="mx-auto h-12 w-12 text-red-500"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                          />
                        </svg>
                      </div>
                      <p className="text-xl font-medium mb-4 text-white">
                        {error}
                      </p>
                      <button
                        onClick={handleRetry}
                        className="px-4 py-2 bg-white text-black rounded hover:bg-gray-200 transition-colors"
                      >
                        {t("app.pages.processing.tryAgain")}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Layout */}
        <div className="md:hidden space-y-6">
          {/* Original Image */}
          <div className="bg-black/40 backdrop-blur-sm rounded-xl p-4 border border-white/10">
            <h3 className="text-lg font-medium mb-3 text-white/90">
              {t("app.pages.processing.originalImage")}
            </h3>
            <div className="h-48 rounded-lg overflow-hidden bg-[#0C0D0D]">
              {originalImage && (
                <img
                  src={originalImage}
                  alt={t("app.pages.processing.originalImageAlt")}
                  className="w-full h-full object-contain"
                />
              )}
            </div>
          </div>

          {/* Mobile Processing Preview */}
          <div className="bg-black/40 backdrop-blur-sm rounded-xl p-4 border border-white/10">
            <h3 className="text-lg font-medium mb-3 text-white/90">
              {t("app.pages.processing.processingPreview")}
            </h3>
            <div className="relative h-48">
              <div className="h-full rounded-lg overflow-hidden bg-[#0C0D0D]">
                {originalImage && (
                  <img
                    src={originalImage}
                    alt={t("app.pages.processing.previewImageAlt")}
                    className="w-full h-full object-contain blur-md brightness-50"
                  />
                )}
              </div>

              {/* Progress Overlay */}
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full px-4">
                {!error ? (
                  <div className="bg-black/50 rounded-xl p-4">
                    {/* Loading Spinner */}
                    <div className="flex items-center justify-center gap-2 mb-3">
                      <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent" />
                      <span className="text-white/90 text-sm">
                        {t("app.pages.processing.status.processing")}
                      </span>
                    </div>

                    <div className="text-center mb-3">
                      <span className="text-3xl font-bold text-white">
                        {t("app.pages.processing.progress.percent", {
                          percent: Math.round(progress),
                        })}
                      </span>
                    </div>

                    {/* Progress Bar */}
                    <div className="w-full bg-gray-700 rounded-full h-2 mb-3 overflow-hidden">
                      <div
                        className="bg-white h-2 rounded-full transition-all duration-300 ease-out"
                        style={{
                          width: `${progress}%`,
                          transition: "width 0.5s ease-out",
                        }}
                      >
                        <div className="animate-pulse bg-white/50 h-full w-full" />
                      </div>
                    </div>

                    <div className="text-center">
                      <p className="mb-1 text-white font-medium">
                        {getStatusMessage()}
                      </p>
                      <p className="text-gray-200 text-sm">
                        {timeElapsed > 45000
                          ? t("app.pages.processing.status.takingLonger")
                          : t("app.pages.processing.status.lessThanMinute")}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="bg-black/50 rounded-xl p-4 text-center">
                    <div className="mb-3">
                      <svg
                        className="mx-auto h-8 w-8 text-red-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                        />
                      </svg>
                    </div>
                    <p className="text-lg font-medium mb-3 text-white">
                      {error}
                    </p>
                    <button
                      onClick={handleRetry}
                      className="px-3 py-1.5 bg-white text-black rounded hover:bg-gray-200 transition-colors text-sm"
                    >
                      {t("app.pages.processing.tryAgain")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Processing;
