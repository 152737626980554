import React from "react";
import { FaArrowLeft, FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PricingSection = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const plans = t("app.pages.PricingSection.plans", {
    returnObjects: true,
  });

  return (
    <div className="px-4 py-6 sm:px-0">
      <div className="mb-6">
        <button
          onClick={() => navigate("/dashboard")}
          className="flex items-center text-[#0198FE] hover:text-[#0182d8] transition-colors duration-300"
        >
          <FaArrowLeft className="mr-2" />{" "}
          {t("app.pages.PricingSection.backToDashboard")}
        </button>
      </div>

      <div className="bg-white shadow-lg rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <div className="max-w-7xl mx-auto">
            <h1 className="text-2xl font-semibold text-gray-900 mb-4 text-center">
              {t("app.pages.PricingSection.title")}
            </h1>
            <p className="text-center text-gray-600 mb-8">
              {t("app.pages.PricingSection.subtitle")}
            </p>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-6xl mx-auto">
              {plans.map((plan, index) => (
                <div
                  key={index}
                  className={`bg-white rounded-lg shadow-md border-2 transition-all duration-300 hover:shadow-lg ${
                    plan.highlight
                      ? "border-[#0198FE] relative transform scale-105"
                      : "border-gray-100"
                  }`}
                >
                  {plan.highlight && (
                    <div className="absolute top-0 right-0 bg-[#0198FE] text-white text-xs font-bold px-3 py-1 rounded-bl-lg">
                      {t("app.pages.PricingSection.mostPopular")}
                    </div>
                  )}
                  <div className="p-6">
                    <h3 className="text-xl font-semibold text-gray-900 mb-2">
                      {plan.name}
                    </h3>
                    <div className="text-3xl font-bold text-gray-900 mb-4">
                      ${plan.price}
                    </div>
                    <div className="text-sm text-gray-500 mb-4">
                      {t("app.pages.PricingSection.creditsLabel", {
                        count: plan.credits,
                      })}
                    </div>
                    <ul className="space-y-3 mb-6">
                      {plan.features.map((feature, idx) => (
                        <li
                          key={idx}
                          className="flex items-start text-gray-700"
                        >
                          <FaCheck className="w-5 h-5 mr-2 text-[#0198FE] flex-shrink-0 mt-0.5" />
                          <span className="text-sm">{feature}</span>
                        </li>
                      ))}
                    </ul>
                    <button
                      onClick={() => (window.location.href = plan.paymentUrl)}
                      className={`w-full py-2.5 px-4 rounded-lg font-semibold text-white transition-all duration-200 ${
                        plan.highlight
                          ? "bg-[#0198FE] hover:bg-[#0182d8]"
                          : "bg-gray-800 hover:bg-gray-900"
                      }`}
                    >
                      {plan.cta}
                    </button>
                  </div>
                </div>
              ))}
            </div>

            <p className="text-center text-gray-500 mt-8 text-sm">
              {t("app.pages.PricingSection.disclaimer")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingSection;
