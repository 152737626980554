// components/StagingTypeSelector.jsx
import React from "react";
import { useTranslation } from 'react-i18next';
import { HomeIcon, PhotoIcon } from '@heroicons/react/24/outline';

const StagingTypeSelector = ({ value, onChange }) => {
  const { t } = useTranslation();

  const options = [
    {
      id: "empty_room",
      icon: HomeIcon,
    },
    {
      id: "existing_furniture",
      icon: PhotoIcon,
    },
  ];

  return (
    <div className="w-full">
      <h2 className="text-sm font-medium text-gray-700 mb-3">
        {t('app.components.staging_type_selector.title')}
      </h2>
      
      <div className="grid grid-cols-2 gap-4">
        {options.map((option) => (
          <label
            key={option.id}
            className={`
              relative cursor-pointer rounded-lg p-4 transition-all duration-200
              ${
                value === option.id
                  ? "bg-blue-50 border-2 border-[#0198FE]"
                  : "bg-white border-2 border-gray-200 hover:border-[#0198FE]/50"
              }
              flex flex-col items-center text-center
            `}
          >
            <input
              type="radio"
              name="stagingType"
              value={option.id}
              checked={value === option.id}
              onChange={(e) => onChange(e.target.value)}
              className="sr-only"
            />

            <option.icon 
              className={`h-8 w-8 mb-2 ${
                value === option.id ? "text-[#0198FE]" : "text-gray-400"
              }`}
            />

            <div>
              <p className={`font-medium ${
                value === option.id ? "text-[#0198FE]" : "text-gray-900"
              }`}>
                {t(`app.components.staging_type_selector.options.${option.id}.title`)}
              </p>
              <p className="text-xs text-gray-500 mt-1">
                {t(`app.components.staging_type_selector.options.${option.id}.description`)}
              </p>
            </div>

            {value === option.id && (
              <div className="absolute top-2 right-2">
                <div className="h-5 w-5 bg-[#0198FE] rounded-full flex items-center justify-center">
                  <svg
                    className="h-3 w-3 text-white"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M5 13l4 4L19 7" />
                  </svg>
                </div>
              </div>
            )}
          </label>
        ))}
      </div>
    </div>
  );
};

export default StagingTypeSelector;