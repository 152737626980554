import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { supabase } from "../api/supabaseClient";
import { FaBars, FaTimes, FaUser } from "react-icons/fa";

const Header = ({ user }) => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dropdownRef = useRef(null);

  const handleSignOut = async () => {
    await supabase.auth.signOut();
    navigate("/login");
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const isActive = (path) => location.pathname === path;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location.pathname]);

  const handleNavClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <header className="fixed top-0 w-full bg-[#0C0D0D] border-b border-gray-800 z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <Link
                to="/"
                className="text-xl font-bold text-white hover:text-gray-200 transition-colors"
              >
                {t("app.components.header.brand")}
              </Link>
              {/* Desktop Navigation */}
              <nav className="hidden md:flex ml-10 space-x-6">
                <Link
                  to="/dashboard"
                  className={`${
                    isActive("/dashboard")
                      ? "text-white font-semibold"
                      : "text-gray-400 hover:text-white"
                  } transition-colors`}
                >
                  {t("app.components.header.nav.dashboard")}
                </Link>
                <Link
                  to="/prices"
                  className={`${
                    isActive("/prices")
                      ? "text-white font-semibold"
                      : "text-gray-400 hover:text-white"
                  } transition-colors`}
                >
                  {t("app.components.header.nav.prices")}
                </Link>
                <Link
                  to="/results"
                  className={`${
                    isActive("/results")
                      ? "text-white font-semibold"
                      : "text-gray-400 hover:text-white"
                  } transition-colors`}
                >
                  {t("app.components.header.nav.results")}
                </Link>
              </nav>
            </div>

            {/* Desktop user info and CTA */}
            <div className="hidden md:flex items-center space-x-6">
              <Link
                to="/upload-image"
                className="bg-white hover:bg-gray-200 text-[#0C0D0D] font-bold py-2 px-6 rounded-lg transition-colors duration-200 flex items-center"
              >
                {t("app.components.header.cta.createNew")}
              </Link>
              {/* Language Selector for Desktop */}
              <div className="relative inline-block text-left">
                <select
                  onChange={(e) => changeLanguage(e.target.value)}
                  value={i18n.language}
                  className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="en">English</option>
                  <option value="es">Español</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
              <div className="relative" ref={dropdownRef}>
                <button
                  onClick={toggleDropdown}
                  className="flex items-center space-x-2 text-gray-400 hover:text-white focus:outline-none transition-colors"
                >
                  <div className="w-8 h-8 rounded-full bg-gray-800 flex items-center justify-center">
                    <FaUser size={16} />
                  </div>
                </button>
                {isDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-[#1A1A1A] rounded-lg shadow-lg py-1 z-10 border border-gray-800">
                    <div className="px-4 py-2 text-sm text-gray-400 border-b border-gray-800">
                      {user?.email}
                    </div>
                    <button
                      onClick={handleSignOut}
                      className="block w-full text-left px-4 py-2 text-sm text-gray-300 hover:bg-gray-800 transition-colors"
                    >
                      {t("app.components.header.auth.logout")}
                    </button>
                  </div>
                )}
              </div>
            </div>

            {/* Mobile menu button */}
            <div className="md:hidden flex items-center space-x-3">
              <Link
                to="/upload-image"
                className="bg-white hover:bg-gray-200 text-[#0C0D0D] font-bold py-2 px-4 text-sm rounded-lg transition-colors duration-200 flex items-center"
              >
                {t("app.components.header.cta.newImage")}
              </Link>
              <button
                onClick={toggleMenu}
                className="text-gray-400 hover:text-white focus:outline-none transition-colors"
              >
                {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <div className="md:hidden bg-[#1A1A1A] border-t border-gray-800">
            <div className="px-2 pt-2 pb-3 space-y-1">
              <Link
                to="/dashboard"
                onClick={handleNavClick}
                className={`block px-3 py-2 rounded-lg ${
                  isActive("/dashboard")
                    ? "bg-gray-800 text-white"
                    : "text-gray-400 hover:bg-gray-800 hover:text-white"
                } transition-colors`}
              >
                {t("app.components.header.nav.dashboard")}
              </Link>
              <Link
                to="/prices"
                onClick={handleNavClick}
                className={`block px-3 py-2 rounded-lg ${
                  isActive("/prices")
                    ? "bg-gray-800 text-white"
                    : "text-gray-400 hover:bg-gray-800 hover:text-white"
                } transition-colors`}
              >
                {t("app.components.header.nav.prices")}
              </Link>
              <Link
                to="/results"
                onClick={handleNavClick}
                className={`block px-3 py-2 rounded-lg ${
                  isActive("/results")
                    ? "bg-gray-800 text-white"
                    : "text-gray-400 hover:bg-gray-800 hover:text-white"
                } transition-colors`}
              >
                {t("app.components.header.nav.results")}
              </Link>
            </div>
            <div className="pt-4 pb-3 border-t border-gray-800">
              <div className="flex items-center px-5">
                <div className="text-sm font-medium text-gray-300">
                  {user?.email}
                </div>
              </div>
              <div className="mt-3 px-2 space-y-1">
                <button
                  onClick={() => {
                    handleNavClick();
                    handleSignOut();
                  }}
                  className="block w-full text-left px-3 py-2 rounded-lg text-gray-400 hover:bg-gray-800 hover:text-white transition-colors"
                >
                  {t("app.components.header.auth.logout")}
                </button>
                {/* Language Selector for Mobile */}
                <div
                  className={`relative inline-block text-left mr-2 transition-opacity duration-300 `}
                >
                  <select
                    onChange={(e) => changeLanguage(e.target.value)}
                    value={i18n.language}
                    className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-2 py-1 pr-6 rounded shadow leading-tight focus:outline-none focus:shadow-outline text-sm"
                  >
                    <option value="en">EN</option>
                    <option value="es">ES</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </header>
      <div className="h-[72px]"></div>
    </>
  );
};

export default Header;
