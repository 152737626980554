// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import Dashboard from "./pages/Dashboard";
import UploadNewImage from "./pages/UploadNewImage";
import ShowResult from "./pages/ShowResult";
import Processing from "./pages/Processing";
import Results from "./pages/Results";
import PricingSection from "./pages/PricingSection";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route
            path="/"
            element={
              <Layout>
                <Dashboard />
              </Layout>
            }
          />
          <Route
            path="/dashboard"
            element={
              <Layout>
                <Dashboard />
              </Layout>
            }
          />
          <Route
            path="/results"
            element={
              <Layout>
                <Results />
              </Layout>
            }
          />
          <Route
            path="/upload-image"
            element={
              <Layout>
                <UploadNewImage />
              </Layout>
            }
          />
          <Route
            path="/result/:id"
            element={
              <Layout>
                <ShowResult />
              </Layout>
            }
          />
          <Route
            path="/processing/:imageId"
            element={
              <Layout fullWidth>
                <Processing />
              </Layout>
            }
          />
          <Route
            path="/prices"
            element={
              <Layout>
                <PricingSection />
              </Layout>
            }
          />
          {/* Add other routes as needed */}
        </Routes>
      </Router>
    </I18nextProvider>
  );
}

export default App;
